<template>
  <div class="setting_main_bg">
    <div class="flexAC">
      <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
    </div>
    <div class="flexAC search_top">
      <el-input v-model="cont"  placeholder="请输入搜索内容" class="contInput"></el-input>
      <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
      <el-button class="settingReset" @click="handleReset">重置</el-button>
    </div>
    <div class="main_table">
      <el-table
          stripe
          :data="maintenanceList"
          style="width: 100%">
        <el-table-column
            label="序号"
            type="index">
        </el-table-column>
        <el-table-column
            label="评分"
            width="80"
            prop="mainscore">
        </el-table-column>
        <el-table-column
            label="公司名称"
            show-overflow-tooltip
            prop="mainname">
        </el-table-column>
        <el-table-column
            label="统一信用码"
            show-overflow-tooltip
            prop="maincode">
        </el-table-column>
        <el-table-column
            label="公司地址"
            show-overflow-tooltip
            prop="mainress">
        </el-table-column>
        <el-table-column
            label="操作"
            width="180">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
            <el-button type="text" size="small" @click="handlePerCom(scope.row)">人员&公司</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!--  维保添加  -->
    <el-dialog title="维保新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <el-form-item label="公司名称" class="foitem" prop="mainname">
          <el-input v-model="addfrom.mainname"  placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="统一信用码" class="foitem" prop="maincode">
          <el-input v-model="addfrom.maincode"  placeholder="请输入统一信用码"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="mainress">
          <el-input v-model="addfrom.mainress" placeholder="请选择公司地址" disabled>
            <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 定位 -->
    <el-dialog class="mapDialog" :visible.sync="mapShow" width="600px"  :close-on-click-modal="false" :before-close="AddressClose">
      <Address :modify="modifyAdd" :isNoPca="isNoPca" @close="AddressClose" @save="AddressSave"></Address>
    </el-dialog>

    <!-- 维保编辑 -->
    <el-dialog title="维保编辑" :visible.sync="editFromShow" width="600px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <el-form-item label="公司名称" class="foitem" prop="mainname">
          <el-input v-model="editfrom.mainname"  placeholder="请输入公司名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="统一信用码" class="foitem" prop="maincode">
          <el-input v-model="editfrom.maincode"  placeholder="请输入统一信用码" disabled></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="mainress">
          <el-input v-model="editfrom.mainress" placeholder="请选择公司地址" disabled>
            <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>
<!--            <el-button slot="append" icon="el-icon-location"></el-button>-->
          </el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!--  人员&公司   -->
    <el-dialog title="人员&公司" :visible.sync="showPerCom" width="900px" center class="gyDialog">
      <div class="perComBox flexCB">
        <div class="per_left">
          <div class="per_LTop flexCB">
            <div class="comList flexCB"><p>绑定企业</p><p>{{ companyNum }}</p></div>
            <div class="comListBox" @click="handlePerAdd">添加</div>
          </div>
          <div class="per_LCom">
            <div class="flexAC LComRow" v-for="(item, index) in userList"
              :key="index"
              :class="item.userid === muserid ? 'userBox':''"
              @click="changeUser(item)">
              <p>{{ item.username }}</p>
              <p style="margin-left: 8px;">{{ item.userphone }}</p>
            </div>
            <div style="text-align: center" v-if="userList.length === 0">暂无人员</div>
          </div>
        </div>
        <div class="per_right">
          <div class="per_RCom">
            <div class="prRow flexCB" v-for="(item, index) in company" :key="index">
              <div class="prRowName">
                <p v-if="index < 9">{{ '0' + (index+1) }}</p>
                <p v-if="index >= 9">{{ index+1 }}</p>
                <p class="prRowTitle">{{ item.companyname }}</p>
              </div>
              <p>{{ item.creatime }}</p>
            </div>
            <div v-if="company.length === 0" style="text-align: center">暂无数据</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!--  人员添加  -->
    <el-dialog title="添加人员" :visible.sync="showPerFrom" width="500px" center class="gyDialog" :close-on-click-modal="false" :before-close="perFromCancel">
      <el-form :model="perfrom" :rules="perFormRules" ref="perFrom">
        <el-form-item label="姓名"  prop="mainuser">
          <el-input v-model="perfrom.mainuser"  placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号"  prop="mainphone">
          <el-input v-model="perfrom.mainphone"  placeholder="请输入手机号"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="perFromCancel">取消</div>
        <div class="settingConfirm" @click="perFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Address from '@/components/setting/address.vue'
import { getMainNance, addMainNance, editMainNance, deleteMainNance, perGetMN, perAddMN, getMCompany } from '@/http/api/maintenance'
import { isValDateMobile } from '@/utils/validate.js'
export default {
  components: {
    Address
  },
  data () {
    // 名称重复校验
    const checkRepeatName = (rule, value, callback) => {
      this.maintenanceList.forEach(item => {
        if (item.mainname === value) {
          return callback(new Error('公司名称重复'))
        }
      })
      return callback()
    }
    // 信用码重复校验
    const checkRepeatCode = (rule, value, callback) => {
      this.maintenanceList.forEach(item => {
        if (item.maincode === value) {
          return callback(new Error('社会统一信用码重复'))
        }
      })
      return callback()
    }
    // 信用码校验
    const creditCode = (rule, value, callback) => {
      let Ancode // 统一社会信用代码的每一个值
      let Ancodevalue // 统一社会信用代码每一个值的权重
      let total = 0
      const weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28] // 加权因子
      // 不用I、O、S、V、Z
      const str = '0123456789ABCDEFGHJKLMNPQRTUWXY'
      for (let i = 0; i < value.length - 1; i++) {
        Ancode = value.substring(i, i + 1)
        Ancodevalue = str.indexOf(Ancode)
        total = total + Ancodevalue * weightedfactors[i]
        // 权重与加权因子相乘之和
      }
      let logiccheckcode = 31 - total % 31
      if (logiccheckcode === 31) {
        logiccheckcode = 0
      }
      const Str = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y'
      const ArrayStr = Str.split(',')
      logiccheckcode = ArrayStr[logiccheckcode]

      const checkcode = value.substring(17, 18)
      if (logiccheckcode !== checkcode) {
        return callback(new Error('校验失败，请输入正确的统一社会信用代码！'))
      }
      return callback()
    }
    // 电话校验
    const checkPhone = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入手机号'))
      }
      if (isValDateMobile(value)[0]) {
        callback(new Error(isValDateMobile(value)[1]))
      } else {
        callback()
      }
    }
    const checkPhone1 = (rule, value, callback) => {
      this.userList.forEach(item => {
        if (item.userphone === value) {
          return callback(new Error('该手机号已添加'))
        }
      })
      return callback()
    }
    return {
      cont: '',
      maintenanceList: [],
      page: 1,
      size: 10,
      total: 0,
      addFromShow: false,
      addfrom: {
        mainname: '',
        maincode: '',
        mainress: '',
        mainlong: '',
        mainlat: ''
      },
      formRules: {
        mainname: [
          { required: true, trigger: 'blur', message: '公司名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
          { validator: checkRepeatName, message: '公司名称重复', trigger: 'blur' }
        ],
        maincode: [
          { required: true, trigger: 'change', message: '统一社会信用代码不能为空' },
          { type: 'string', max: 18, trigger: 'change', message: '输入有误，统一社会信用代码长度需为18位' },
          { validator: checkRepeatCode, trigger: 'blur' },
          { validator: creditCode, trigger: 'change' }
        ],
        mainress: [
          { required: true, trigger: 'change', message: '请选择公司地址' }
        ]
      },
      mapShow: false,
      address: '',
      editFromShow: false,
      editfrom: {},
      editformRules: {
        mainname: [
          { required: true, trigger: 'blur', message: '公司名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        maincode: [
          { required: true, trigger: 'change', message: '统一社会信用代码不能为空' },
          { type: 'string', max: 18, trigger: 'change', message: '输入有误，统一社会信用代码长度需为18位' },
          { validator: creditCode, trigger: 'change' }
        ],
        mainress: [
          { required: true, trigger: 'change', message: '请选择公司地址' }
        ]
      },
      showPerCom: false,
      mainid: '',
      companyNum: 0,
      company: [],
      userList: [],
      muserid: '',
      showPerFrom: false,
      perfrom: {
        mainuser: '',
        mainphone: ''
      },
      perFormRules: {
        mainuser: { required: true, trigger: 'blur', message: '姓名不能为空' },
        mainphone: [
          { required: true, validator: checkPhone, trigger: 'blur' },
          { validator: checkPhone1, trigger: 'change' }
        ]
      },
      modifyAdd: null,
      isNoPca: true
    }
  },
  watch: {
    // 'addfrom.mainress' (val) {
    //   this.address = val
    // },
    // 'editfrom.mainress' (val) {
    //   this.address = val
    // }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const parmas = {
        mainname: this.cont,
        page_index: this.page,
        page_size: this.size
      }
      getMainNance(parmas).then(res => {
        if (res.status === '1') {
          this.maintenanceList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.maintenanceList = []
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.cont = ''
      this.init()
    },
    /* 展示编辑 */
    showAddress () {
      this.mapShow = true
      if (this.addFromShow === true) {
        this.modifyAdd = null
      } else {
        let obj = {
          address: this.editfrom.mainress,
          longitude: this.editfrom.mainlong,
          latitude: this.editfrom.mainlat
        }
        this.modifyAdd = obj
      }
    },
    AddressClose () {
      this.mapShow = false
    },
    /* 新增 */
    handleAdd () {
      this.addFromShow = true
    },
    /* 地址-保存 */
    AddressSave (res) {
      this.mapShow = false
      this.modifyAdd = {...res}
      if (this.addFromShow === true) { // 新增表单
        this.addfrom.mainress = res.address
        this.addfrom.mainlong = res.longitude
        this.addfrom.mainlat = res.latitude
      } else if (this.editFromShow === true) { // 编辑表单
        this.editfrom.mainress = res.address
        this.editfrom.mainlong = res.longitude
        this.editfrom.mainlat = res.latitude
      }
    },
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
    // 添加提交
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          mainname: this.addfrom.mainname,
          maincode: this.addfrom.maincode,
          mainress: this.addfrom.mainress,
          mainlong: this.addfrom.mainlong,
          mainlat: this.addfrom.mainlat
        }
        addMainNance(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
    },
    // 关闭编辑弹框
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    // 编辑提交
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          mainress: this.editfrom.mainress,
          mainlong: this.editfrom.mainlong,
          mainlat: this.editfrom.mainlat,
          mainid: this.editfrom.mainid
        }
        editMainNance(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteMainNance({ mainid: row.mainid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    handlePerCom (row) {
      this.showPerCom = true
      this.mainid = row.mainid
      this.getPerson()
      this.getCompanyNum()
    },
    // 获取人员
    getPerson () {
      const params = {
        mainid: this.mainid
      }
      perGetMN(params).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            this.userList = res.data
            this.muserid = this.userList[0].userid
            this.getCompany()
          } else {
            this.userList = []
          }
        } else {
          this.userList = []
        }
      })
    },
    // 绑定总数
    getCompanyNum () {
      const params = {
        mainid: this.mainid,
        muserid: ''
      }
      getMCompany(params).then(res => {
        if (res.status === '1') {
          this.companyNum = res.data.count
        } else {
          this.companyNum = 0
        }
      })
    },
    // 绑定公司
    getCompany () {
      const params = {
        mainid: this.mainid,
        muserid: this.muserid
      }
      getMCompany(params).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            this.company = res.data
          } else {
            this.company = []
          }
        } else {
          this.company = []
        }
      })
    },
    handlePerAdd () {
      this.showPerFrom = true
    },
    perFromCancel () {
      this.showPerFrom = false
      this.$refs.perFrom.resetFields()
    },
    // 人员添加提交
    perFromConfirm () {
      this.$refs.perFrom.validate((valid) => {
        if (!valid) return
        const params = {
          mainid: this.mainid,
          mainuser: this.perfrom.mainuser,
          mainphone: this.perfrom.mainphone
        }
        perAddMN(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.perFromCancel()
            this.getPerson()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    changeUser (item) {
      this.muserid = item.userid
      this.getCompany()
    }
  }
}
</script>

<style scoped>
.main_table{
  margin-top: 20px;
}
.perComBox{
  width: 100%;
  height: 500px;
}
.per_left{
  width: 236px;
  height: 100%;
}
.per_right{
  width: 513px;
  height: 100%;
}
.per_LTop{
  width: 100%;
  height: 35px;
}
.comList{
  width: 160px;
  height: 35px;
  background: #F6F9FF;
  border-radius: 4px;
  color: #6F7E90;
  font-size: 14px;
  padding: 0px 12px;
  box-sizing: border-box;
}
.comListBox{
  width: 60px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #196DF7;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.per_LCom{
  width: 100%;
  height: auto;
  margin-top: 10px;
  overflow-y: scroll;
  scrollbar-width: none;/* //火狐兼容 */
}
.per_RCom{
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: none;/* //火狐兼容 */
}
.per_RCom::-webkit-scrollbar,.per_LCom::-webkit-scrollbar {
  width: 1px;
}
.LComRow{
  width: 100%;
  height: 35px;
  background: #F6F9FF;
  border: 1px solid #F6F9FF;
  opacity: 1;
  border-radius: 4px;
  padding: 0 12px;
  box-sizing: border-box;
  margin-top: 10px;
  cursor: pointer;
}
.userBox{
  border: 1px solid #196DF7;
}
.prRow{
  width: 100%;
  height: 50px;
  background: #F6F9FF;
  opacity: 1;
  border-radius: 4px;
  padding: 0px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.prRow:last-child {
  margin-bottom: 0px;
}
.prRowName{
  width: 70%;
  display: flex;
  align-items: center;
}
.prRowTitle{
  margin-left: 20px;
}
</style>
